import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                fill="#070512"
                d="M10 20C4.486 20 0 15.513 0 10 0 4.485 4.486 0 10 0s10 4.486 10 10c0 5.513-4.486 10-10 10zm8.433-8.631c-.292-.093-2.643-.794-5.32-.365 1.117 3.069 1.572 5.57 1.658 6.09a8.555 8.555 0 003.662-5.725zm-5.096 6.507c-.128-.75-.624-3.362-1.823-6.477l-.056.02c-4.817 1.677-6.547 5.018-6.701 5.333A8.498 8.498 0 0010 18.555a8.49 8.49 0 003.337-.68zm-9.683-2.152c.194-.33 2.538-4.213 6.944-5.636.11-.038.223-.07.335-.102a27.806 27.806 0 00-.692-1.45C5.976 9.813 1.836 9.76 1.463 9.752c-.002.087-.005.173-.005.261 0 2.193.831 4.196 2.196 5.711zM1.64 8.264c.381.005 3.9.02 7.896-1.04a54.11 54.11 0 00-3.167-4.94 8.569 8.569 0 00-4.73 5.98zm6.36-6.556c.235.316 1.787 2.43 3.186 5 3.038-1.137 4.324-2.866 4.477-3.085A8.51 8.51 0 0010 1.471c-.69 0-1.36.082-2.002.237zm8.612 2.903c-.18.244-1.611 2.078-4.77 3.368a27.071 27.071 0 01.752 1.679c2.841-.358 5.665.215 5.948.273a8.504 8.504 0 00-1.93-5.32z"
            ></path>
        </svg>
    )
}

export default Icon
