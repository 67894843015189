import styled from 'styled-components'

import { media } from '@variables'

export const Wrapper = styled.div<{ readonly maxWidth?: number }>`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    width: 100%;

    ${media.tablet} {
        max-width: 420px;
    }
    ${media.tablet} {
        max-width: 750px;
    }
    ${media.desktop} {
        max-width: 970px;
    }
    ${media.xl} {
        max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 1270)}px;
    }
`
