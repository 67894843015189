import React, { ReactNode, CSSProperties } from 'react'

import { Wrapper } from './styled'

type Props = {
    children: ReactNode | ReactNode[]
    maxWidth?: number
    style?: CSSProperties
}

const Container = ({ children, maxWidth, style = {} }: Props) => (
    <Wrapper maxWidth={maxWidth} style={style}>
        {Array.isArray(children) ? children.map((c) => c) : children}
    </Wrapper>
)

export default Container
