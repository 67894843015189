import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Wrap, Ico } from './styled'
import ArrowIco from 'client/components/SVG/Arrow'

export type Props = {
    children: ReactNode | string
    onClick?: () => void
    href?: string
    to?: string
    variant?: 'ARROW' | 'NORMAL'
}

const Button = ({ children, onClick, to, href, variant = 'NORMAL' }: Props) => {
    if (to) {
        return (
            <Wrap onClick={onClick} withLink variant={variant}>
                <Link to={to}>
                    {variant === 'ARROW' && (
                        <Ico>
                            <ArrowIco />
                        </Ico>
                    )}
                    {children}
                </Link>
            </Wrap>
        )
    }
    if (href) {
        return (
            <Wrap onClick={onClick} withLink variant={variant}>
                <a href={href} target="_blank" rel="noreferrer noopener">
                    {variant === 'ARROW' && (
                        <Ico>
                            <ArrowIco />
                        </Ico>
                    )}
                    {children}
                </a>
            </Wrap>
        )
    }
    return (
        <Wrap onClick={onClick} withLink={false} variant={variant}>
            {variant === 'ARROW' && (
                <Ico>
                    <ArrowIco />
                </Ico>
            )}
            <span>{children}</span>
        </Wrap>
    )
}

export default Button
