import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                fill="#070512"
                d="M10 0C7.284 0 6.944.012 5.877.06 4.813.11 4.086.278 3.45.525a4.901 4.901 0 00-1.772 1.153A4.901 4.901 0 00.525 3.45C.278 4.086.109 4.813.06 5.877.011 6.944 0 7.284 0 10s.011 3.056.06 4.123c.049 1.064.218 1.791.465 2.427a4.902 4.902 0 001.153 1.772 4.902 4.902 0 001.772 1.153c.636.247 1.363.416 2.427.465 1.067.048 1.407.06 4.123.06s3.056-.012 4.123-.06c1.064-.049 1.791-.218 2.427-.465a4.904 4.904 0 001.772-1.153 4.904 4.904 0 001.153-1.772c.247-.636.416-1.363.465-2.427.048-1.067.06-1.407.06-4.123s-.012-3.056-.06-4.123c-.049-1.064-.218-1.791-.465-2.427a4.902 4.902 0 00-1.153-1.772A4.902 4.902 0 0016.55.525C15.914.278 15.187.109 14.123.06 13.056.012 12.716 0 10 0zm0 1.802c2.67 0 2.986.01 4.04.058.976.045 1.505.207 1.858.344a3.1 3.1 0 011.15.748c.35.35.566.684.748 1.15.137.353.3.882.344 1.857.048 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.04-.045.976-.207 1.505-.345 1.858a3.1 3.1 0 01-.748 1.15 3.1 3.1 0 01-1.15.748c-.352.137-.881.3-1.856.344-1.055.048-1.37.058-4.041.058-2.67 0-2.987-.01-4.04-.058-.976-.045-1.505-.207-1.858-.345a3.099 3.099 0 01-1.15-.748 3.099 3.099 0 01-.748-1.15c-.137-.352-.3-.881-.344-1.856-.048-1.055-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.04.045-.976.207-1.505.344-1.858.182-.466.398-.8.748-1.15.35-.35.684-.566 1.15-.748.353-.137.882-.3 1.857-.344 1.055-.048 1.37-.058 4.041-.058z"
            ></path>
            <path
                fill="#070512"
                d="M9.999 13.332a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667zm0-8.469a5.135 5.135 0 100 10.27 5.135 5.135 0 000-10.27zM16.54 4.667a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0z"
            ></path>
        </svg>
    )
}

export default Icon
