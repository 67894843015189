import get from 'lodash/get'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

import { Routes } from '@variables'
import Button from 'client/components/shared/Button'
import Container from 'client/components/shared/Container'
import { Row, Text, Link, Reject, Wrapper, ButtonWrapper } from './styled'

const COOKIE_NAME = 'QUSION_WEB_IS_COOKIE_ACCEPTED'

const Cookie = () => {
    const [t] = useTranslation()
    const [isSetted, setIsSetted] = useState(false)
    const [cookies, setCookie] = useCookies([COOKIE_NAME])

    if (get(cookies, `${COOKIE_NAME}`) || isSetted) {
        return null
    }

    const oneYearFromNow = new Date()
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

    const options = { expires: oneYearFromNow }

    const accept = () => {
        setIsSetted(true)
        setCookie(COOKIE_NAME, true, options)
    }

    const reject = () => {
        setIsSetted(true)
        setCookie(COOKIE_NAME, false, options)
    }

    return (
        <Wrapper>
            <Container>
                <Row>
                    <Text>
                        {t('cookie-banner-text')}{' '}
                        <Link
                            rel="noreferrer noopener"
                            href={Routes.COOKIE}
                            target="_blank"
                        >
                            {t('learn-more')}
                        </Link>
                    </Text>
                    <ButtonWrapper>
                        <div>
                            <Reject onClick={reject}>{t('reject')}</Reject>
                        </div>
                        <div>
                            <Button onClick={accept}>{t('accept')}</Button>
                        </div>
                    </ButtonWrapper>
                </Row>
            </Container>
        </Wrapper>
    )
}

export default Cookie
