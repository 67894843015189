import styled from 'styled-components'

import { AccessibleButton } from '../shared/styled'
import { colors, media, transition } from '@variables'

export const Wrapper = styled.div`
    background: ${colors.primary.white};
    position: fixed;
    z-index: 3;
    width: 100%;
    bottom: 0;
    left: 0;

    padding: 25px 0;
`

export const Text = styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    ${media.phone} {
        padding-right: 50px;
    }
`

export const Link = styled.a`
    text-decoration: underline;
    transition: ${transition.main};

    :visited {
    }
    ${media.tablet} {
        :hover {
        }
    }
`

export const Row = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.tablet} {
        align-items: center;
        flex-direction: row;
    }
`

export const Reject = styled(AccessibleButton)`
    height: 56px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            text-decoration: underline;
        }
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    button:last-of-type {
        margin: 0 0 0 20px;
        @media (max-width: 853px) {
            margin: 15px 0 0 20px;
        }
    }
`
