import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from '@variables'
import Social from 'client/components/shared/Social'
import Button from 'client/components/shared/Button'
import LogoImg from '@static/images/logo-small.webp'
import Container from 'client/components/shared/Container'
import { Top, Middle, SocialWrap, Bottom, TitleWrap } from './styled'

const Footer = () => {
    const [t] = useTranslation()
    const { pathname } = useLocation()

    const isContactsPage = pathname === Routes.CONTACTS

    return (
        <Container maxWidth={1270}>
            {!isContactsPage && (
                <Top>
                    <TitleWrap>
                        <h2>{t('footer-title1')}</h2>
                        <h2>{t('footer-title2')}</h2>
                    </TitleWrap>
                    <div>
                        <Button to={Routes.CONTACTS}>
                            {t('get-in-touch')}
                        </Button>
                    </div>
                </Top>
            )}
            {!isContactsPage && (
                <Middle>
                    <div>
                        <h6>{t('offices')}</h6>
                        <p>
                            <Link to={Routes.CONTACTS}>{t('prague')}</Link>
                        </p>
                        <p>
                            <Link to={Routes.CONTACTS}>
                                {t('san-francisco')}
                            </Link>
                        </p>
                    </div>
                    <div>
                        <h6>{t('nav-contact')}</h6>
                        <p>
                            <a href="mailto:hello@qusion.com">
                                hello@qusion.com
                            </a>
                        </p>
                        <p>
                            <a href="tel:+420605764690">+420 605 764 690</a>
                        </p>
                    </div>
                    <SocialWrap>
                        <Social type="dribbble" />
                        <Social type="facebook" />
                        <Social type="github" />
                        <Social type="instagram" />
                        <Social type="linkedin" />
                    </SocialWrap>
                </Middle>
            )}
            <Bottom>
                <Link to={Routes.HOME}>
                    <img
                        src={LogoImg}
                        alt="qusion"
                        width="40"
                        height="38"
                        loading="lazy"
                    />
                </Link>
                <h6>{t('copyrights')}</h6>
            </Bottom>
        </Container>
    )
}

export default Footer
