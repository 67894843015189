import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <g clipPath="url(#clip0_3432_11594)">
                <path
                    fill="#070512"
                    d="M14.622 11.165l.55-3.592h-3.446V5.242c0-.983.482-1.941 2.025-1.941h1.568V.243S13.896 0 12.537 0C9.698 0 7.843 1.72 7.843 4.835v2.738H4.687v3.592h3.156v8.684a12.517 12.517 0 003.883 0v-8.684h2.896z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_3432_11594">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Icon
