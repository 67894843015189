import { colors, media, transition } from '@variables'
import styled from 'styled-components'

export const Top = styled.div`
    max-width: 600px;
    padding-bottom: 120px;
`

export const TitleWrap = styled.div`
    padding-bottom: 30px;
`

export const Middle = styled.div`
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        padding-bottom: 80px;
        justify-content: flex-end;
    }

    div {
        margin: 0 0 40px 0;

        ${media.tablet} {
            margin: 0 0 0 125px;
        }
    }

    h6 {
        padding-bottom: 15px;
    }

    a {
        color: inherit;
        text-decoration: inherit;
        transition: ${transition.main};

        ${media.tablet} {
            :hover {
                color: ${colors.secondary.blue};
            }
        }
    }
`

export const SocialWrap = styled.div`
    display: flex;
    max-width: 320px;
    padding-top: 40px;
    flex-direction: row;
    justify-content: space-between;

    ${media.tablet} {
        max-width: inherit;
        padding-top: 0px;
        flex-direction: column;
        justify-content: flex-start;
    }

    div {
        margin-left: 0;

        ${media.tablet} {
            :not(:last-of-type) {
                margin-bottom: 24px;
            }
        }
    }
`

export const Bottom = styled.div`
    width: 100%;
    padding: 45px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        color: inherit;
        text-decoration: inherit;
        transition: ${transition.main};

        ${media.tablet} {
            :hover {
                color: ${colors.secondary.blue};
            }
        }
    }

    :before {
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: 0.1;
        content: ' ';
        display: block;
        position: absolute;
        background-color: ${colors.primary.black};
    }
`
