import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M5.356 18V7.419H2.1V18h3.256zm0-14.332C5.336 2.72 4.71 2 3.688 2 2.668 2 2 2.721 2 3.668c0 .926.648 1.668 1.649 1.668h.02c1.04 0 1.687-.742 1.687-1.668zM10.668 18v-6.029c0-.322.023-.645.118-.875.258-.645.846-1.313 1.833-1.313 1.292 0 2.103.737 2.103 2.188V18H18v-6.19c0-3.316-1.762-4.86-4.113-4.86-1.927 0-2.773 1.083-3.243 1.82l.024-1.415H7.396c.047 1.013 0 10.645 0 10.645h3.272z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
