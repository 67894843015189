import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                fill="#070512"
                fillRule="evenodd"
                d="M8.145 18.126v-1.534c-.209.026-.397.048-.58.065-1.366.13-2.45-1.392-3.057-2.432-.331-.57-.683-1.14-1.335-1.505a.421.421 0 01-.12-.115c-.016-.021-.034-.043-.053-.064l.029-.018a.605.605 0 01.083-.047.601.601 0 01.122-.022c.459-.026.892.044 1.233.318.224.178.428.391.596.613.642.858 1.748 2.219 2.873 1.932.153-.04.24-.096.255-.248.03-.41.163-.797.423-1.11-.185-.05-.371-.097-.557-.144-.437-.11-.87-.218-1.277-.374-1.182-.453-1.93-1.236-2.17-2.337-.153-.714-.092-1.432.01-2.145.092-.632.306-1.219.866-1.676.04-.035.046-.135.02-.187-.27-.631-.24-1.262-.02-1.902.066-.191.173-.213.367-.16.51.134.922.395 1.273.726.126.116.243.24.36.363l.175.185c1.06-.587 4.09-.587 5.202-.009.065-.065.13-.13.194-.197.146-.15.293-.3.452-.442.342-.3.74-.531 1.223-.644.158-.04.245-.009.3.135.25.63.296 1.262 0 1.893-.055.126-.05.209.067.304.351.288.565.649.677 1.053.214.753.26 1.524.2 2.29-.067.822-.429 1.544-1.137 2.123-.596.483-1.31.77-2.094.94-.218.047-.437.084-.665.122l-.21.035c.391.514.488 1.07.473 1.654a21.21 21.21 0 01-.006.222v.237L12.342 18a8.333 8.333 0 10-4.195.126zM20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
