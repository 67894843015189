import styled, { css } from 'styled-components'

import { Props } from '.'
import { AccessibleButton } from '../styled'
import { colors, media, transition } from '@variables'

export const Ico = styled.div`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: ${transition.main};
    background-color: ${colors.primary.white};
    box-shadow: 0px 1px 1px rgba(90, 93, 238, 0.4);
`

type P = Props & { withLink: boolean | undefined }

export const Wrap = styled(AccessibleButton)<P>`
    display: flex;
    font-size: 12px;
    padding: 20px 48px;
    line-height: normal;
    align-items: center;
    border-radius: 100px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${colors.primary.black};
    transition: ${transition.main};
    background-color: ${colors.primary.white};
    box-shadow: 0px 1px 1px rgba(90, 93, 238, 0.4);

    ${media.tablet} {
        :hover {
            box-shadow: 0px 3px 3px rgba(90, 93, 238, 0.2);
        }
    }

    ${({ withLink }) =>
        withLink &&
        css`
            padding: 0;
            a {
                width: 100%;
                color: inherit;
                padding: 20px 48px;
                text-decoration: none;
                display: flex;
                align-items: center;
            }
        `}

    ${({ variant }) =>
        variant === 'ARROW' &&
        css`
            padding: 0;
            border-radius: 0;
            box-shadow: none !important;
            background-color: transparent;

            a {
                padding: 0;
            }

            div {
                margin-right: 16px;
            }

            ${media.tablet} {
                :hover {
                    ${Ico} {
                        box-shadow: 0px 3px 3px rgba(90, 93, 238, 0.2);
                    }
                }
            }
        `}
`
