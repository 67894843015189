import React from 'react'
import styled from 'styled-components'

import { colors, media, Routes, transition } from '@variables'
import Github from 'client/components/SVG/Social/github'
import Facebook from 'client/components/SVG/Social/fb'
import Instagram from 'client/components/SVG/Social/instagram'
import Linkedin from 'client/components/SVG/Social/linkedin'
import Dribbble from 'client/components/SVG/Social/dribbble'

type Props = {
    type: 'dribbble' | 'facebook' | 'instagram' | 'linkedin' | 'github'
}

const Wrap = styled.div`
    width: 20px;
    height: 20px;
    background-color: transparent;

    svg {
        transition: ${transition.main};
        path {
            transition: ${transition.main};
        }
    }

    a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        ${media.tablet} {
            :hover {
                svg {
                    fill: ${colors.secondary.blue};
                    path {
                        fill: ${colors.secondary.blue};
                    }
                }
            }
        }
    }
`

const Social = ({ type }: Props) => {
    return (
        <Wrap>
            <a target="_blank" rel="noreferrer noopener" href={getLink(type)}>
                {getIcon(type)}
            </a>
        </Wrap>
    )
}

const getLink = (type: Props['type']) => {
    if (type === 'dribbble') {
        return Routes.DRIBBBLE
    }
    if (type === 'facebook') {
        return Routes.FACEBOOK
    }
    if (type === 'instagram') {
        return Routes.INSTAGRAM
    }
    if (type === 'linkedin') {
        return Routes.LINKEDIN
    }
    return Routes.GITHUB
}

const getIcon = (type: Props['type']) => {
    if (type === 'dribbble') {
        return <Dribbble />
    }
    if (type === 'facebook') {
        return <Facebook />
    }
    if (type === 'instagram') {
        return <Instagram />
    }
    if (type === 'linkedin') {
        return <Linkedin />
    }
    return <Github />
}

export default Social
