import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            width="12"
            height="6"
            fill="none"
            viewBox="0 0 12 6"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="#070512"
                fillRule="evenodd"
                d="M9.17 5.827l-.707-.707 1.623-1.623H0v-1h10.082L8.462.877 9.17.17 11.29 2.29l.707.707-.707.707L9.17 5.827z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
